// Feedback.js
import React, { useState } from 'react';
import '../styles/Feedback.css';
import Header from './Header';

const Feedback = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        comments: ''
    });
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setIsSubmitted(false);

        try {
            const response = await fetch('http://localhost:5000/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setFormData({ name: '', email: '', comments: '' });
                setMessage('Feedback submitted successfully!');
                setIsSubmitted(true);

                setTimeout(() => {
                    setIsSubmitted(false);
                }, 2000);
            } else {
                throw new Error('Failed to submit feedback');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred. Please try again later.');
            setIsSubmitted(false);
        }
    };

    return (
        <div className="feedback-page">
            <Header showMenuIcon={false} onZuriaClick={() => window.location.href = '/'} />

            <div className="feedback-container">
                <h1 className="feedback-title">Feedback</h1>
                <h4 className="feedback-subtitle">
                    We’re on a mission to bring AI to everyone’s fingertips. <br />
                    We appreciate any insights that help us do this better.
                </h4>
                <form className="feedback-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        className="feedback-input"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="feedback-input"
                        required
                    />
                    <textarea
                        name="comments"
                        placeholder="Comments"
                        value={formData.comments}
                        onChange={handleChange}
                        className="feedback-textarea"
                        required
                    ></textarea>
                    <button
                        type="submit"
                        className={`feedback-submit ${isSubmitted ? 'submitted' : ''}`}
                    >
                        {isSubmitted ? 'Submitted' : 'Submit'}
                    </button>
                </form>

                {message && (
                    <p className={`feedback-message ${isSubmitted ? 'success' : 'error'}`}>
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
};

export default Feedback;
