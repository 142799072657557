// Content.js
import React, { useState, useEffect, useRef } from 'react';
import '../styles/sec1.css';
import Header from './Header';
import CategoryRotation from './CategoryRotation';
import CardRotation from './CardRotation';

const Content = () => {
    const [companies, setCompanies] = useState([]);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

    const categories = ["operations", "accounting", "marketing", "content", "writing", "excel"];

    // References for each section
    const landingRef = useRef(null);
    const sec2Ref = useRef(null);
    const sec3Ref = useRef(null);
    const sec4Ref = useRef(null);

    useEffect(() => {
        fetch('/data/companies.json')
            .then((response) => response.json())
            .then((data) => setCompanies(data));
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentCategoryIndex((prevIndex) => (prevIndex + 1) % categories.length);
        }, 2500); 
        return () => clearInterval(interval);
    }, [categories.length]);

    // Scroll to the specific section
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Header
                onZuriaClick={() => scrollToSection(landingRef)}
                onFindClick={() => scrollToSection(sec2Ref)}
                onBenchmarkClick={() => scrollToSection(sec3Ref)}
                onIntegrateClick={() => scrollToSection(sec4Ref)}
            />
            <div className="scroll-container">
                <section className="main-content" ref={landingRef}>
                    <div className="centered-layout">
                        <div className="landing-title">
                            <h1>Plug and play AI for:</h1>
                            <h1><CategoryRotation categories={categories} currentCategoryIndex={currentCategoryIndex} /></h1>
                        </div>
                        <div className="cards-container">
                            <CardRotation companies={companies} categories={categories} currentCategoryIndex={currentCategoryIndex} />
                        </div>
                    </div>
                </section>
                <section className="sec2" ref={sec2Ref}>
                    <div className="sec2content">
                        <div className="content-box">
                            <h1 className="sect">find.</h1>
                            <h2 className="secm">Quickly compare the top AI apps.</h2>
                            <h4 className="secd1">Only bundle the apps you need.</h4>
                        </div>
                    </div>
                </section>
                <section className="sec3" ref={sec3Ref}>
                    <div className="sec3content">
                        <div className="content-box">
                            <h1 className="sect">benchmark.</h1>
                            <h2 className="secm">Optimize your workflows.</h2>
                            <h4 className="secd1">Stay compatible with your existing systems.</h4>
                        </div>
                    </div>
                </section>
                <section className="sec4" ref={sec4Ref}>
                    <div className="sec4content">
                        <div className="content-box">
                            <h1 className="sect">integrate.</h1>
                            <h2 className="secm">One-click integration.</h2>
                            <h4 className="secd1">Integrate an entire suite of AI tools from one place.</h4>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Content;
