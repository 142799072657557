import React, { useRef, useEffect, useState } from 'react';
import Card from './Card';
import '../styles/Card.css';

const CardRotation = ({ companies, categories, currentCategoryIndex }) => {
  const cardContainerRef = useRef(null);
  const [showLeftBlur, setShowLeftBlur] = useState(false);
  const [showRightBlur, setShowRightBlur] = useState(false);

  const currentCategory = categories[currentCategoryIndex];
  const displayedCompanies = companies.filter((company) => company.category === currentCategory);

  const updateShadows = () => {
    const container = cardContainerRef.current;
    setShowLeftBlur(container.scrollLeft > 0);
    setShowRightBlur(container.scrollLeft + container.clientWidth < container.scrollWidth);
  };

  useEffect(() => {
    const container = cardContainerRef.current;

    if (container && container.children.length > 0) {
      const cardElement = container.children[0];
      const cardWidth = cardElement.offsetWidth;
      const gap = parseFloat(getComputedStyle(container).gap) || 0;
      const containerWidth = container.clientWidth;
      const middleIndex = Math.floor(displayedCompanies.length / 2);

      const initialScrollPosition = (cardWidth + gap) * middleIndex - (containerWidth - cardWidth) / 2;
      container.scrollLeft = initialScrollPosition;

      updateShadows(); // Initial shadow update
    }

    container.addEventListener('scroll', updateShadows);
    return () => container.removeEventListener('scroll', updateShadows);
  }, [displayedCompanies.length]);

  return (
    <div className="card-rotation-wrapper">
      <div className={`blur-left ${showLeftBlur ? 'show' : ''}`}></div>
      <div
        className="card-rotation-container"
        ref={cardContainerRef}
        onMouseDown={(e) => {
          e.preventDefault();
          cardContainerRef.current.isDragging = true;
          cardContainerRef.current.startX = e.pageX - cardContainerRef.current.offsetLeft;
        }}
        onMouseMove={(e) => {
          const container = cardContainerRef.current;
          if (!container.isDragging) return;
          e.preventDefault();
          const x = e.pageX - container.offsetLeft;
          const walk = (x - container.startX) * 2;
          container.scrollLeft = container.scrollLeft - walk;
        }}
        onMouseUp={() => { cardContainerRef.current.isDragging = false; }}
        onMouseLeave={() => { cardContainerRef.current.isDragging = false; }}
        onContextMenu={(e) => e.preventDefault()}
      >
        {displayedCompanies.map((company, index) => (
          <Card key={index} company={company} />
        ))}
      </div>
      <div className={`blur-right ${showRightBlur ? 'show' : ''}`}></div>
    </div>
  );
};

export default CardRotation;
