// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Content from './components/Content';
import Feedback from './components/Feedback';
import Waitlist from './components/Waitlist'; // Import Waitlist component
import './styles/App.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Content />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/waitlist" element={<Waitlist />} /> {/* New Waitlist route */}
            </Routes>
        </Router>
    );
}

export default App;
