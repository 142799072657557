// Card.js
import React from 'react';
import '../styles/Card.css';

const Card = ({ company }) => (
  <div className="card-container">
    <div className="card">
      <div className="card-section title-section">
        <p className="column-title">{company.title}</p>
        <div className="logo-section">
          <img 
            src={company.logo} 
            alt={company.name} 
            className="logo-img" 
          />
        </div>
      </div>

      <div className = "text-section">
        <div className="card-section price-section">
          <p className="price">{company.price}</p>
        </div>

        <div className="card-section price-tag-section">
          <p className="description">{company.price_tag1}</p>
        </div>

        <div className="card-section description-section">
          <p className="description">{company.why_tag1}</p>
        </div>
      </div>
      

      <div className="card-section button-section">
        <a href={company.link} target="_blank" rel="noopener noreferrer">
          <button className="try-now-button">Try now</button>
        </a>
      </div>
    </div>
  </div>
);

export default Card;
