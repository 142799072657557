// CategoryRotation.js
import React from 'react';

const CategoryRotation = ({ categories, currentCategoryIndex }) => {
  const currentCategory = categories[currentCategoryIndex];

  return (
    <span className="highlighted-text">
      <span className="text1">{currentCategory}</span>
    </span>
  );
};

export default CategoryRotation;
