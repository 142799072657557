// Header.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Header.css';
import menu from '../menu.png';

const Header = ({ onFindClick, onBenchmarkClick, onIntegrateClick, onZuriaClick, showMenuIcon = true }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigation = (callback) => {
    callback();
    setIsMenuOpen(false); // Close menu after navigation
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="header-container">
      <div className="zuria-bubble" onClick={onZuriaClick}>
        <span className="zuria-name">zuria.</span>
      </div>
      <header className="header">
        <div className="header-left">
          <span className="nav-item" onClick={onFindClick}>find</span>
          <span className="nav-item" onClick={onBenchmarkClick}>benchmark</span>
          <span className="nav-item" onClick={onIntegrateClick}>integrate</span>
        </div>
        <div className="header-right">
          <span className="nav-item" onClick={() => navigate('/feedback')}>feedback</span>
        </div>
      </header>
      {showMenuIcon && (
        <div className="menu-icon" onClick={toggleMenu}>
          <img src={menu} alt="menu" className="menu" />
        </div>
      )}
      {isMenuOpen && (
        <div className="overlay-menu" ref={menuRef}>
          <span className="overlay-item" onClick={() => handleNavigation(onFindClick)}>find</span>
          <span className="overlay-item" onClick={() => handleNavigation(onBenchmarkClick)}>benchmark</span>
          <span className="overlay-item" onClick={() => handleNavigation(onIntegrateClick)}>integrate</span>
          <span className="overlay-item" onClick={() => handleNavigation(() => navigate('/feedback'))}>feedback</span>
        </div>
      )}
    </div>
  );
};

export default Header;
